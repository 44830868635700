"use client";

import { revalidate } from "@/actions/revalidate";
import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { trpc } from "@/utils/trpc";
import { useUser } from "@clerk/nextjs";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Check } from "lucide-react";
import { useState } from "react";

export function OrganizationSwitcher() {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const { user, isLoaded: userIsLoaded } = useUser();

  const trpcCtx = trpc.useUtils();
  const {
    data: organizations,
    isLoading,
    isError,
  } = trpc.organizations.list.useQuery();

  const { mutateAsync, isLoading: switchIsLoading } =
    trpc.admin.switchOrg.useMutation({
      onSuccess: async () => {
        void trpcCtx.invalidate();
        await revalidate("/");
        await user?.reload();
        toast({
          description: "Org switched successfully!",
        });
      },
      onError: () => {
        toast({
          description: "Failed to switch orgs.",
          variant: "destructive",
        });
      },
    });

  const { orgId, orgName } =
    (user?.publicMetadata as Record<string, string | undefined>) ?? {};

  return (
    <>
      {organizations?.length && organizations.length > 0 ? (
        <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
          <DialogTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="h-10"
            >
              {orgId ? (
                orgName
              ) : (
                <span className="flex items-center text-destructive">
                  <ExclamationCircleIcon className="mr-2 h-5 w-5" />
                  No org
                </span>
              )}
            </Button>
          </DialogTrigger>
          <DialogContent className="w-full p-0">
            <Command className="h-72">
              <CommandInput />
              <CommandEmpty>None found.</CommandEmpty>
              <ScrollArea>
                <CommandGroup>
                  {organizations?.map((organization) => (
                    <CommandItem
                      key={organization.id}
                      onSelect={async () => {
                        await mutateAsync({
                          id: organization.id,
                          name: organization.name,
                        });
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          organization.id === orgId
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                      {organization.name}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </ScrollArea>
            </Command>
          </DialogContent>
        </Dialog>
      ) : isLoading || switchIsLoading || !userIsLoaded ? (
        <Button variant="outline" role="combobox" aria-expanded={open} disabled>
          <Icons.spinner className="h-5 w-5 animate-spin text-muted-foreground" />
        </Button>
      ) : isError ? (
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          disabled
          className="text-destructive"
        >
          <ExclamationCircleIcon className="mr-2 h-5 w-5" /> Org error
        </Button>
      ) : (
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          disabled
          className="text-destructive"
        >
          <ExclamationCircleIcon className="mr-2 h-5 w-5" />
          {/* Add some */}
          No orgs
        </Button>
      )}
    </>
  );
}
