import { getAbsoluteUrl, getBaseUrl } from "@/lib/utils";
import {
  AcademicCapIcon,
  BuildingOfficeIcon,
  HomeIcon,
  MapIcon,
  TruckIcon,
  type UsersIcon,
} from "@heroicons/react/24/outline";

// Defining these explicitly to get better type support for next typedRoutes
export type SiteBaseRoutes =
  | "/"
  | "/dashboard"
  | "/routes"
  | "/buses"
  | "/contractors"
  | "/schools";

export interface SiteNavigationType {
  name: string;
  href: SiteBaseRoutes;
  icon?: typeof UsersIcon;
}

export const siteConfig: {
  name: string;
  url: string;
  ogImage: string;
  description: string;
  navigation: SiteNavigationType[];
  homeNavigation: SiteNavigationType[];
  links: Record<string, string>;
} = {
  name: "BusBuddy",
  url: getBaseUrl(),
  ogImage: getAbsoluteUrl("og"), // TODO: Add og image
  description:
    "Simplify school bus routes and stops for schools, parents, and students. Find the nearest bus stop effortlessly.",
  navigation: [
    { name: "Dashboard", href: "/dashboard", icon: HomeIcon },
    {
      name: "Routes",
      href: "/routes",
      icon: MapIcon,
    },
    {
      name: "Buses",
      href: "/buses",
      icon: TruckIcon,
    },
    {
      name: "Contractors",
      href: "/contractors",
      icon: BuildingOfficeIcon,
    },
    {
      name: "Schools",
      href: "/schools",
      icon: AcademicCapIcon,
    },
  ],
  homeNavigation: [{ name: "Home", href: "/", icon: HomeIcon }],
  links: {
    tekomics: "#",
  },
};

export type SiteConfig = typeof siteConfig;
