"use client";

import { revalidate } from "@/actions/revalidate";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";
import { useClerk, UserProfile, useUser } from "@clerk/nextjs";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useCallback, useState } from "react";

export function ClerkUserDropdown() {
  const router = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const { user, isLoaded } = useUser();
  const { signOut } = useClerk();

  const email = user?.emailAddresses?.[0]?.emailAddress;
  const name = [user?.firstName, user?.lastName].filter(Boolean).join(" ");
  const { orgId, orgName, orgRole, admin } =
    (user?.publicMetadata as Record<string, string | undefined>) ?? {};

  const handleSignOut = useCallback(async () => {
    await revalidate("/");
    await signOut();
    router.push("/");
  }, [signOut]);

  return (
    <>
      <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
        <DialogContent className="!w-auto max-w-full p-0">
          <UserProfile />
        </DialogContent>
      </Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="relative h-8 w-8 p-0">
            {user?.imageUrl ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={user?.imageUrl}
                className="h-full w-full rounded-full"
              />
            ) : !isLoaded ? (
              <Skeleton className="h-8 w-8 rounded-full" />
            ) : (
              <div className="h-8 w-8 rounded-full bg-muted" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-2">
              <p className="text-sm font-medium leading-none">{name}</p>
              <p className="text-xs leading-none text-muted-foreground">
                {email}
              </p>
              {orgId && (
                <p className="text-xs font-semibold leading-none text-muted-foreground">
                  {orgName}
                </p>
              )}
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={() => setOpen(true)}
            >
              Account
            </DropdownMenuItem>
          </DropdownMenuGroup>

          {admin && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem className="hover:cursor-pointer" asChild>
                  <Link href="/admin/organizations">Organizations (admin)</Link>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </>
          )}

          {(orgRole === "owner" || (admin && orgId)) && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem className="hover:cursor-pointer" asChild>
                  <Link href="/organization">Organization</Link>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </>
          )}

          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onClick={handleSignOut}
          >
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
